import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'

export interface ErrorMonitor {
  logError: (err: any) => void
  withErrorBoundary: <T>(cb: (...args: any) => T) => (...args: any) => Promise<T>
  bindActions: <T>(actions: T) => T
}

export const initSentry = (controller: IWidgetControllerConfig, dsn: string): ErrorMonitor => {
  if (typeof window !== 'undefined') {
    return noopMonitor()
  }

  const monitor = controller.platformAPIs.monitoring.createMonitor(dsn)
  return {
    logError: logError(monitor),
    withErrorBoundary: withErrorBoundary(monitor),
    bindActions: bindActions(monitor),
  }
}

const bindActions = (monitor) => <T>(actions: T) =>
  Object.entries(actions).reduce((result, [key, action]) => {
    result[key] = withErrorBoundary(monitor)(action)
    return result
  }, {} as T)

const logError = (monitor) => (e) => {
  monitor.captureException(e)
  console.error(e)
}

const withErrorBoundary = (monitor) => (cb) => async (...params) => {
  try {
    return await cb(...params)
  } catch (e) {
    monitor.captureException(e)
    console.error(e)
  }
}

const noopMonitor = (): ErrorMonitor => ({
  logError: () => null,
  withErrorBoundary: (cb) => async (...params) => cb(...params),
  bindActions: (actions) => actions,
})
