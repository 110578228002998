import {AnyAction} from 'redux'
import {RESERVE_TICKETS} from '../actions/reservation'
import {PendingRequests} from '../types'
import {SEND_RSVP} from '../actions/rsvp'
import {PLACE_ORDER} from '../actions/placed-order'

const defaultState: PendingRequests = {
  requestNames: [],
}

export const pendingRequests = (state: PendingRequests = defaultState, action: AnyAction) => {
  const monitorRequests = [RESERVE_TICKETS, SEND_RSVP, PLACE_ORDER]

  const actionType = monitorRequests.find((type) => [type.REQUEST, type.SUCCESS, type.FAILURE].includes(action.type))

  if (!actionType) {
    return state
  }

  if (actionType.REQUEST === action.type) {
    return markRequestPending(state, actionType.REQUEST)
  }

  return markRequestCompleted(state, actionType.REQUEST)
}

const markRequestPending = (state: PendingRequests, name: string) => ({
  ...state,
  requestNames: [...state.requestNames, name],
})

const markRequestCompleted = (state: PendingRequests, name: string) => ({
  ...state,
  requestNames: state.requestNames.filter((testName) => testName !== name),
})
