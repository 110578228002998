import {INPUT_NAMES} from '@wix/wix-events-commons-statics'
import {TFunction} from 'i18next'
import {RegistrationErrorKey} from '../../commons/enums'
import {State, RegFormData} from '../types'
import {FormStep} from '../constants/constants'

export const shouldNavigateToForm = (state: State) => {
  const eventControls = state?.event?.form?.controls
  const rsvpControls = state?.memberRsvp?.rsvp?.rsvpForm?.inputValues

  if (eventControls && rsvpControls) {
    const filteredEventControls = eventControls
      .filter(({name}) => name !== INPUT_NAMES.NAME && name !== INPUT_NAMES.EMAIL)
      .map(({name}) => name)
      .sort()
    const filteredRsvpControls = rsvpControls
      .filter(
        ({inputName}) =>
          inputName !== INPUT_NAMES.EMAIL &&
          inputName !== INPUT_NAMES.FIRST_NAME &&
          inputName !== INPUT_NAMES.LAST_NAME &&
          inputName !== INPUT_NAMES.GUEST_NAMES,
      )
      .map(({inputName}) => inputName)
      .sort()
    const eventControlsContainsAllRsvpControls = filteredEventControls.every((val) =>
      filteredRsvpControls.includes(val),
    )

    return filteredEventControls.length < filteredRsvpControls.length || !eventControlsContainsAllRsvpControls
  } else {
    return false
  }
}

export const getRsvpError = (state: State, t: TFunction) => {
  const handlers = {
    [RegistrationErrorKey.RSVP_CLOSED]: () => t('formError.rsvpClosed'),
    [RegistrationErrorKey.GUEST_LIMIT_REACHED]: () =>
      t('formError.guestLimitExceeded', {remainingSlots: state.memberRsvp.error.max_rsvp_size}),
    [RegistrationErrorKey.WAITING_LIST_UNAVAILABLE]: () => t('formError.waitingListUnavailable'),
    [RegistrationErrorKey.GUEST_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RegistrationErrorKey.MEMBER_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RegistrationErrorKey.UNEXPECTED_RSVP_RESPONSE]: () => t('formError.unexpectedRsvpResponse'),
    [RegistrationErrorKey.UNKNOWN_RSVP_ERROR]: () => t('formError.generalError'),
  }

  const errorHandlerKey = Object.keys(handlers).find((key) => key === state.memberRsvp.error?.error_key)

  return errorHandlerKey && handlers[errorHandlerKey]()
}

export const isStepVisible = ({step, hasPolicies}: IsStepVisibleArgs) => {
  switch (step) {
    case FormStep.Policies:
      return hasPolicies
    default:
      return true
  }
}

export const isStepCompleted = ({step, rsvpDetails, agreedWithPolicies}: IsStepCompletedArgs) => {
  switch (step) {
    case FormStep.BuyerDetails:
      return Boolean(rsvpDetails)
    case FormStep.Policies:
      return agreedWithPolicies
    default:
      return false
  }
}

export const getCurrentStep = (state: State) => state.rsvp.currentStep

export const getRsvpDetails = (state: State) => state.rsvp.rsvpDetails

export const getResponse = (state: State) => state.rsvp.response

interface IsStepVisibleArgs {
  step: string
  hasPolicies: boolean
}

interface IsStepCompletedArgs {
  step: string
  rsvpDetails: RegFormData
  agreedWithPolicies: boolean
}
