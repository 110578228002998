import {IStyle} from '@wix/native-components-infra/dist/es/src/types/types'
import {WEEKDAY} from '@wix/wix-events-commons-statics'
import {AppProps} from '../components/app/interfaces'
import {CalendarLayout} from '../types/state'

export const getEventDetailsStyleHash = (context: AppProps) => {
  const eventDetailsSettings = {
    booleans: ['listShowImage', 'listShowFullDate', 'listShowLocation', 'listShowDescription', 'listShowSocialShare'],
    numbers: [
      'calendarDetailsTitleTextSize',
      'calendarDetailsDateLocationTextSize',
      'calendarDetailsDescriptionTextSize',
    ],
  }

  const style: IStyle = context.host.style

  const settings = Object.entries(style.styleParams).reduce((result, [type, params]) => {
    if (eventDetailsSettings[type]) {
      result[type] = Object.entries(params).reduce((typeResult, [key, value]) => {
        if (eventDetailsSettings[type].includes(key)) {
          typeResult[key] = value
        }

        return typeResult
      }, {})
    }

    return result
  }, {})

  return JSON.stringify(settings)
}

export const getCalendarDays = (weeks: CalendarMonth): CalendarDay[] => weeks.flatMap((day) => day)

export const getCalendarDaysWithEvents = ({weeks}: CalendarLayout): CalendarDay[] =>
  getCalendarDays(weeks).filter(withEvents)

export const getCalendarDaysWithMultipleEvents = ({weeks}: CalendarLayout): CalendarDay[] =>
  getCalendarDays(weeks).filter(withMultipleEvents)

export const getCalendarDaysWithOneEvent = ({weeks}: CalendarLayout): CalendarDay[] =>
  getCalendarDays(weeks).filter(withEvent)

export const isCalendarPageLoaded = ({loadedPages}: CalendarLayout, referenceDate: string) =>
  loadedPages.includes(referenceDate)

export const isCalendarPageLoading = ({loading}: CalendarLayout) => !!loading

const withEvents = (day: CalendarDay) => day.events.length > 0
const withMultipleEvents = (day: CalendarDay) => day.events.length > 1
const withEvent = (day: CalendarDay) => day.events.length === 1

export type CalendarMonth = CalendarWeek[]
export type CalendarWeek = CalendarDay[]
export interface CalendarDay {
  weekDay: WEEKDAY
  isCurrentMonth: boolean
  today: boolean
  past: boolean
  timestamp: number
  dayNumber: number
  weekIndex: number
  weekDayIndex: number
  events: wix.events.Event[]
  dayMonthText: string
}
