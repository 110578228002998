import {SERVER_BASE_URL, encodeQueryParams, UrlQueryParams, ModalActions} from '@wix/wix-events-commons-statics'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'

export const MODAL_OPENED = 'MODAL_OPENED'

interface OpenModalProps {
  type: string
  params?: UrlQueryParams
}

export const openModal = ({type, params}: OpenModalProps) => (
  dispatch: Function,
  GetState: GetCommonState,
  {wixCodeApi, compId}: CommonStoreExtraArgs,
): Promise<{message: {reason: string}}> => {
  const queryParams = params ? encodeQueryParams(params) : ''
  dispatch({type: MODAL_OPENED, modalType: type, params})
  return wixCodeApi.window.openPopup(
    `${SERVER_BASE_URL}/html/modal/${type}${queryParams}`,
    {
      theme: 'BARE',
      width: '100%' as any,
      height: '100%' as any,
      position: {
        origin: 'FIXED',
        placement: 'CENTER',
        x: 0,
        y: 0,
      },
    },
    compId,
  )
}

interface OpenGeneralModalProps extends OpenModalProps {
  onConfirm?: (response?: any) => void
  onClose?: (response?: any) => void
}

export const openDialogModal = ({onConfirm, onClose, ...rest}: OpenGeneralModalProps) => (dispatch: Function) =>
  dispatch(openModal(rest)).then((response) => {
    if (response.message.reason === ModalActions.OK && onConfirm) {
      onConfirm(response)
    } else if (onClose) {
      onClose(response)
    }
  })
