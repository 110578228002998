export const SELECT_TICKET = 'SELECT_TICKET'
export const UNSELECT_TICKET = 'UNSELECT_TICKET'

export const selectTicket = (ticketId: string, count: number) => ({
  type: SELECT_TICKET,
  payload: {ticketId, count},
})

export const unselectTicket = (ticketId: string, count: number) => ({
  type: UNSELECT_TICKET,
  payload: {ticketId, count},
})
