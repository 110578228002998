import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {GetState} from '../types'

export const GET_CHECKOUT_OPTIONS = createActions('GET_CHECKOUT_OPTIONS')

export const getCheckoutOptions = () => (dispatch: Function, getState: GetState) => {
  if (!getState().checkoutOptions.loaded) {
    return dispatch(callAPI(GET_CHECKOUT_OPTIONS))
  } else {
    return Promise.resolve()
  }
}
