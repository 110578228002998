import {AnyAction} from 'redux'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'

export const event = (state: ExtendedEvent = null, action: AnyAction) => {
  switch (action.type) {
    case GET_EVENT.SUCCESS:
      return action.payload
    case GET_MEMBERS.SUCCESS:
      return {
        ...state,
        members: action.payload.members,
        totalGuests: action.payload.totalGuests,
      }
    default:
      return state
  }
}
