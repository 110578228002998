import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isTemplate} from '../../commons/selectors/instance'
import {openDialogModal} from '../../commons/services/modal'
import {isRequestPending} from '../selectors/pending-requests'
import {GetState, SelectedTickets, StoreExtraArgs} from '../types'
import {isUpgradeNeeded} from '../selectors/checkout-options'
import {isMobile} from '../../commons/selectors/environment'
import {UserRole} from '../constants/constants'
import {openCheckoutUnavailable} from './modals'
import {navigateToMobileCheckout, navigateToTicketForm} from './navigation'

export const RESERVE_TICKETS = createActions('RESERVE_TICKETS')
export const CANCEL_RESERVATION = createActions('CANCEL_RESERVATION')

export const reserveTickets = (eventId: string, selectedTickets: SelectedTickets) => (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const upgradeNeeded = isUpgradeNeeded(state)
  const owner = wixCodeApi.user.currentUser.role === UserRole.ADMIN

  if (isTemplate(state)) {
    return isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
  }

  if (upgradeNeeded && (isMobile(state) || !owner)) {
    return dispatch(openCheckoutUnavailable())
  }

  if (upgradeNeeded && owner) {
    return dispatch(
      openDialogModal({
        type: 'upgrade-to-premium',
        onClose: (options: {message: {proceed?: any}}) => {
          if (options.message.proceed) {
            dispatch(makeReservation(eventId, selectedTickets))
          }
        },
      }),
    )
  }

  return dispatch(makeReservation(eventId, selectedTickets))
}

export const cancelReservation = (eventId: string, reservationId: string) => (dispatch: Function) => {
  if (reservationId) {
    dispatch(callAPI(CANCEL_RESERVATION, eventId, reservationId))
  }
}

const makeReservation = (eventId: string, tickets: SelectedTickets) => async (
  dispatch: Function,
  getState: GetState,
  {fedopsLogger}: StoreExtraArgs,
) => {
  const state = getState()

  if (!isRequestPending(state, RESERVE_TICKETS.REQUEST)) {
    fedopsLogger.createReservationStarted()
    await dispatch(callAPI(RESERVE_TICKETS, eventId, tickets))
    isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
  }
}
