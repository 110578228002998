import {AnyAction} from 'redux'
import {SELECT_TICKET, UNSELECT_TICKET} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case SELECT_TICKET:
    case UNSELECT_TICKET:
      return {...state, [action.payload.ticketId]: action.payload.count}
    default:
      return state
  }
}
